<template>
  <v-card outlined>
    <v-card-title class="align-start">
      <v-row>
        <v-col cols="12">Performances par article</v-col>
      </v-row>

      <dot-menu
        :isLoading="loading"
        @exportCsv="exportCSV"
        :csvButton="{
          csvData,
          filename: `perf-par-article-${this.startDate}_${this.endDate}.csv`,
          disabled: searchAndExportDisabled,
        }"
        @exportExcel="exportExcel"
        :excelButton="{
          excelData,
          filename: `perf-par-article-${this.startDate}_${this.endDate}.xlsx`,
          disabled: searchAndExportDisabled,
        }"
      >
        <template v-slot:extraButtons>
          <persist-data-button
            v-if="'Keep this element as usage example' === ''"
            title="Prévision inventaire sur la sélection"
            :data="articleIds.join(',')"
            :disabled="
              loading || articleIds.length <= 0 || articleIds.length > 200
            "
            alt="La sélection doit être comprise entre 1 et 200 articles"
            storageKey="audience/perf-per-article/article-list"
            @dataPersisted="goToForecast"
          />
        </template>
      </dot-menu>
    </v-card-title>
    <v-card-title class="align-start">
      <v-row>
        <v-col cols="6">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Recherche (par : Site, Redac, Catégorie, Titre, URL, ...)"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <search-type store="audience" />
            </v-col>
            <v-col cols="5">
              <search-column
                :columns="['site', 'category', 'redac', 'title', 'url']"
                store="audience"
              />
            </v-col>
            <v-col cols="2">
              <apply-search :disabled="searchAndExportDisabled" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <table-stats :data="stats"></table-stats>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :page="options.page"
        :pageCount="numberOfPages"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :sort-by="sortBy"
        :sort-desc="true"
        :search="search"
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
        }"
        :items-per-page="options.itemsPerPage"
      >
        <template v-slot:item.adn_post_id="{ item }">
          <v-btn
            v-if="item.adn_post_id"
            class="ma-2"
            text
            icon
            @click.native="
              dialogModel = {
                dialog: !dialogModel.dialog,
                id: item.adn_post_id,
              }
            "
            ><v-icon>{{ icons.mdiChartLine }}</v-icon></v-btn
          >
        </template>
        <template v-slot:item.title="{ item }">
          <div class="truncateCell" :title="item.title">
            {{ item.title }}
          </div>
        </template>
        <template v-slot:item.adn_post_url_path_identifier="{ item }">
          <div class="truncateCell" :title="item.adn_post_url_path_identifier">
            <a :href="item.url" target="_blank">{{
              item.adn_post_url_path_identifier
            }}</a>
          </div>
        </template>
        <template v-slot:item.meta_description="{ item }">
          <div class="truncateCell" :title="item.meta_description">
            {{ item.meta_description }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="dialogModel.dialog" width="70vw">
      <post-performances-modal
        :dialogOpen="dialogModel.dialog"
        :id="dialogModel.id"
        :startDate="this.startDate"
        :endDate="this.endDate"
        :periodType="this.periodType"
      ></post-performances-modal>
    </v-dialog>
  </v-card>
</template>

<style scoped>
.truncateCell {
  max-height: 80px;
  overflow: hidden;
}
</style>

<script>
import debounce from "lodash/debounce";
import { mdiChartLine } from "@mdi/js";

import ApplySearch from "@/components/common/filters/ApplySearch";
import DotMenu from "@/components/common/menus/DotMenu";
import PersistDataButton from "@/components/common/menus/buttons/PersistDataButton";
import PostPerformancesModal from "@/components/audience/perf-per-article/modals/PostPerformances";
import SearchColumn from "@/components/common/filters/SearchColumn";
import SearchType from "@/components/common/filters/SearchType";
import TableStats from "@/components/audience/perf-per-article/cards/TableStatsCard";

import useAxios from "@/hooks/useAxios";
import { getRoute } from "@/router";
import { runParallelAsyncs } from "@/utils/async";
import {
  decodeHtmlEntities,
  formatCurrency,
  formatNumber,
} from "@/utils/formatting";

export default {
  name: "PerfPerArticle",
  components: {
    ApplySearch,
    DotMenu,
    PostPerformancesModal,
    PersistDataButton,
    SearchColumn,
    SearchType,
    TableStats,
  },
  data() {
    const canSeeAllData =
      this.$can("manage", "audience") || this.$can("manage", "regie_with_ca");

    return {
      optionsInitialized: false,
      forceCall: false,
      canSeeAllData,
      icons: {
        mdiChartLine,
      },
      dialogModel: {
        dialog: false,
        id: -1,
      },
      siteList: [],
      articleIds: [],
      stats: {},
      search: "",
      numberOfPages: 0,
      total: 0,
      items: [],
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      csvData: "",
      excelData: new Blob([""], { type: "text/plain" }),
      sortBy: canSeeAllData ? "ca" : "views",
      headers: [
        {
          text: "",
          value: "adn_post_id",
          sortable: false,
        },
        {
          text: "Date création",
          align: "start",
          value: "adn_post_creation_date",
        },
        {
          text: "Site",
          value: "site_name",
          sortable: false,
        },
        {
          text: "Rédac",
          align: "start",
          value: "redac_name",
          sortable: false,
        },
        {
          text: "Titre",
          value: "title",
          sortable: false,
        },
        {
          text: "URL",
          value: "adn_post_url_path_identifier",
          sortable: false,
        },
        {
          text: "Méta descr",
          value: "meta_description",
          sortable: false,
        },
        ...(canSeeAllData
          ? [
              {
                text: "CA",
                value: "ca",
              },
            ]
          : []),
        {
          text: "Vues",
          value: "views",
        },
        ...(canSeeAllData
          ? [
              {
                text: "Sessions",
                value: "sessions",
              },
              {
                text: "Vues / Session",
                value: "views_per_session",
                sortable: false,
              },
              {
                text: "RPM",
                value: "rpm",
                sortable: false,
              },
            ]
          : []),
        {
          text: "Nb chars",
          value: "chars_count",
          sortable: false,
        },
        {
          text: "Catégorie",
          value: "category",
          sortable: false,
        },
        {
          text: "New",
          value: "is_new",
          sortable: false,
        },
        {
          text: "Diapo",
          value: "is_diapo",
          sortable: false,
        },
        {
          text: "Typologie",
          value: "ga_typology",
          sortable: false,
        },
        {
          text: "Type",
          value: "content_type",
          sortable: false,
        },
      ],
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    goToForecast() {
      const route = getRoute("regie-forecast");

      this.$router.push(route);
    },
    performances_url_params(forExport = false, forStats = false) {
      return {
        start_date: this.startDate,
        end_date: this.endDate,
        period_type: this.periodType,
        sites_id: this.sites.join(","),
        categories: this.categories.join(","),
        search_text: this.search,
        search_column: this.searchColumn,
        search_type: this.searchType,
        ...(forStats ? {} : { page: Math.max(this.options.page - 1, 0) }),
        ...(forExport
          ? {}
          : { ...(forStats ? {} : { limit: this.options.itemsPerPage }) }),
        ...(forStats ? {} : { sort_by: this.options.sortBy?.[0] ?? "ca" }),
        ...(forStats ? {} : { sort_desc: this.options.sortDesc?.[0] ?? true }),
      };
    },
    formatResult(data) {
      return data.map((item) => {
        const site = this.siteList.find((_site) => _site.id === item.site_id);

        return {
          ...item,
          ...(this.canSeeAllData && item?.ca !== undefined
            ? {
                ca: formatCurrency(item.ca),
                rpm: formatCurrency((item.ca / item.sessions) * 1000),
              }
            : {}),
          views: formatNumber(item.views),
          ...(this.canSeeAllData
            ? {
                sessions: formatNumber(item.sessions),
                views_per_session: formatNumber(item.views / item.sessions),
              }
            : {}),
          chars_count: formatNumber(item.chars_count),
          site_name: site?.name ?? "",
          title: decodeHtmlEntities(item.title),
          meta_description: decodeHtmlEntities(item.meta_description),
        };
      });
    },
    checkDates() {
      if (
        !this.startDate ||
        this.startDate.length !== 10 ||
        !this.endDate ||
        this.endDate.length !== 10
      ) {
        alert("Sélectionnez une plage de date !");
        return false;
      }

      return true;
    },
    async exportCSV() {
      if (!this.checkDates()) {
        return;
      }

      this.loading = true;
      const { data } = await this.axiosGet(
        "/adn-post/performances/export_csv",
        this.performances_url_params(true)
      );

      this.csvData = data;
      this.loading = false;
    },
    async exportExcel() {
      if (!this.checkDates()) {
        return;
      }

      this.loading = true;
      const { data } = await this.axiosGet(
        "/adn-post/performances/export_xlsx",
        this.performances_url_params(true),
        {
          responseType: "blob",
        }
      );

      this.excelData = data;
      this.loading = false;
    },
    debouncedSearch: debounce(async function () {
      if (this.options.page !== 1) {
        this.options.page = 1;
        // updateData automatically call through watcher
      } else {
        this.getData();
      }
    }, 500),
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (
        (this.applyFilters || this.applySearch || this.forceCall) &&
        !this.loading
      ) {
        if (!this.checkDates()) {
          return;
        }
        this.loading = true;
        const {
          performancesResponse: { data: performancesData },
          performancesStatsResponse: { data: performancesStatsData },
          statsResponse: { data: statsData },
        } = await runParallelAsyncs({
          performancesResponse: this.axiosGet(
            "/adn-post/performances",
            this.performances_url_params()
          ),
          performancesStatsResponse: this.axiosGet(
            "/adn-post/performances-stats",
            this.performances_url_params(false, true)
          ),
          statsResponse: this.axiosGet("/audience/stats", {
            start_date: this.startDate,
            end_date: this.endDate,
            sites: this.sites.join(","),
            period_type: this.periodType,
            categories: this.categories.join(","),
          }),
        });

        this.items = this.formatResult(performancesData.items);
        this.total = performancesData.total;
        this.numberOfPages = this.total / this.options.itemsPerPage;
        this.stats = {
          total: this.total,
          stats: performancesStatsData,
        };
        this.articleIds =
          (performancesStatsData.id_list ?? "").split(",") ?? [];

        this.$store.dispatch("audience/updateStatsForPeriod", statsData);

        this.loading = false;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
        this.$store.dispatch("common/updateApplySearch", false);
      }

      this.forceCall = false;
    },
  },
  async created() {
    this.siteList = await this.$store.dispatch("common/getSiteList");
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    applySearch() {
      return this.$store.getters["common/getApplySearch"];
    },
    categories() {
      return this.$store.getters["audience/getCategories"];
    },
    sites() {
      return this.$store.getters["audience/getSites"];
    },
    startDate() {
      return this.$store.getters["audience/getDates"][0];
    },
    endDate() {
      return this.$store.getters["audience/getDates"][1];
    },
    periodType() {
      return this.$store.getters["audience/getPeriodType"];
    },
    searchType() {
      return this.$store.getters["audience/getSearchType"];
    },
    searchColumn() {
      return this.$store.getters["audience/getSearchColumn"];
    },
    searchAndExportDisabled() {
      return this.loading || this.items.length === 0;
    },
  },
  watch: {
    startDate() {
      this.debouncedGetData();
    },
    endDate() {
      this.debouncedGetData();
    },
    periodType() {
      this.debouncedGetData();
    },
    sites() {
      this.debouncedGetData();
    },
    options() {
      if (this.optionsInitialized) {
        // Not on initialization : wait for a real user change
        this.forceCall = true;
      }

      this.optionsInitialized = true;
      this.debouncedGetData();
    },
    applySearch() {
      this.debouncedSearch();
    },
    categories() {
      this.debouncedGetData();
    },
    applyFilters() {
      this.debouncedGetData();
    },
  },
};
</script>
