<template>
  <v-select
    outlined
    clearable
    v-model="selected"
    :items="columns"
    :menu-props="{ maxHeight: '200', zIndex: 13 }"
    label="Dans la colonne"
  ></v-select>
</template>

<script>
/**
 * Call this component specifying a `store`
 *  (and eventually `storeGetter`/`storeUpdater`)
 */
export default {
  name: "SearchColumn",
  props: {
    columns: {
      type: Array,
      validator(values) {
        return (
          values.length > 0 ||
          values.every((value) => typeof value === "string")
        );
      },
    },
    store: {
      type: String,
      validator(value) {
        /* List of stores having :
        - getters["<store>/<storeGetter>"]
        - dispatch("<store>/<storeUpdater>", payload)
        */
        return ["audience", "regie"].includes(value);
      },
    },
    storeGetter: {
      type: String,
      default: "getSearchColumn",
    },
    storeUpdater: {
      type: String,
      default: "updateSearchColumn",
    },
  },
  data() {
    return {};
  },
  computed: {
    selected: {
      get() {
        return this.$store.getters[`${this.store}/${this.storeGetter}`];
      },
      set(e) {
        this.$store.dispatch(`${this.store}/${this.storeUpdater}`, e);
      },
    },
  },
};
</script>

<style scoped></style>
